<template>
  <el-dialog
    class="showImgDialog"
    :center="true"
    title="编辑员工资料"
    :append-to-body="true"
    :visible.sync="dialogVisible"
    @open="getUserInfo"
    @close="close('ruleForm')"
  >
    <el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
      <!-- <el-row type="flex" justify="space-between">
      <el-col :span="11">-->
      <el-form-item label="姓名">
        <el-input disabled v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="工号">
        <el-input v-model="ruleForm.jobNo"></el-input>
      </el-form-item>
      <!-- </el-col> -->
      <!-- <el-col :span="11"> -->

      <!-- </el-col>
      </el-row>-->
      <!-- <el-row type="flex" justify="space-between">
        <el-col :span="11">
          <el-form-item label="电话" prop="phone"
            :rules="[{ required: false, message: '请输入接收通知电话', trigger: 'blur' },
            { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号' }]">
            <el-input disabled type="text" v-model="ruleForm.phone" placeholder="请输入员工电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="性别">
            <el-select v-model="ruleForm.sex" disabled placeholder="请选择性别" style="width: 100%">
              <el-option v-for="item in sexList" :key="item.value" :label="item.value" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="11">
          <el-form-item label="民族" prop="nation">
            <el-select disabled v-model="ruleForm.nation" placeholder="请选择民族" style="width: 100%">
              <el-option v-for="item in nationalityList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="身份证号">
            <el-input disabled v-model="ruleForm.idCardNumber" laceholder="请输入身份证号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="11">
          <el-form-item label="现住址">
            <el-input v-model="ruleForm.native" placeholder="请填写现住址"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="出生日期" prop="birthDay">
            <el-date-picker disabled type="date" placeholder="选择日期" v-model="ruleForm.birthDay" style="width: 100%"
              value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
     
        <el-col :span="11">
          <el-form-item label="籍贯" prop="address">
            <el-input type="text" disabled v-model="ruleForm.address" placeholder="请输入籍贯"></el-input>
          </el-form-item>
      </el-col>-->
      <!-- <el-row type="flex" justify="space-between">
      <el-col :span="11">-->
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" v-model="ruleForm.remark" placeholder="请输入备注"></el-input>
      </el-form-item>
      <!-- </el-col>
      </el-row>-->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">提 交</el-button>
      <el-button @click="closeDialog()">关 闭</el-button>
    </span>
  </el-dialog>
</template>
<script>

import { api } from '/src/api/base';

const staffInfo = api()('staff.info.json');
const staffEdit = api()('user.edit.json');
export default {

  data () {
    return {

      ruleForm: {
        jobNo: '',
        name: '',

        remark: ''
      },
      rules: {},
      nationalityList: '汉族,壮族,满族,回族,苗族,维吾尔族,土家族,彝族,蒙古族,藏族,布依族,侗族,瑶族,朝鲜族,白族,哈尼族,哈萨克族,黎族,傣族,畲族,傈僳族,仡佬族,东乡族,高山族,拉祜族,水族,佤族,纳西族,羌族,土族,仫佬族,锡伯族,柯尔克孜族,达斡尔族,景颇族,毛南族,撒拉族,布朗族,塔吉克族,阿昌族,普米族,鄂温克族,怒族,京族,基诺族,德昂族,保安族,俄罗斯族,裕固族,乌孜别克族,门巴族,鄂伦春族,独龙族,塔塔尔族,赫哲族,珞巴族'.split(','),
      sexList: [
        {
          value: '男',
        },
        {
          value: '女',
        }
      ]

    };
  },
  mouted () {

    this.$set(this.queryParams, 'date1', this.queryParams.applyUseMinDate)

  },
  props: {
    dialogVisible: Boolean,
    code: String,
    staffCode: String
  },
  methods: {
    submitForm (formName) {

      if (this.birthDay === 'Invalid date') {
        this.birthDay = ''
      }

      this.$refs[formName].validate(async valid => {
        if (valid) {
          await staffEdit({
            code: this.code,
            // birthDay: this.birthDay,
            ...this.ruleForm
          });
          this.resetForm(formName);
          this.close(formName)
        } else {
          return false;
        }
      });
    },
    async getUserInfo () {
      const result = await staffInfo({ staffCode: this.staffCode });
      this.ruleForm.name = result.user.name
      this.ruleForm.jobNo = result.user.jobNo
      this.ruleForm.remark = result.user.remark



    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    close (formName) {
      this.resetForm(formName);
      this.closeDialog()
    },
    closeDialog () {
      this.$emit('update:dialogVisible', false);
      this.$emit('close', this.dialogVisible);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}

.el-dialog__body {
  max-height: 500px;
  overflow: auto;
  padding: 10px 40px;
}
</style>
